import { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import {
  WidgetBuilder,
  FlowEditorSDK,
  FlowAPI,
  IHttpClient,
} from '@wix/yoshi-flow-editor';
import { defaultHelpId } from '../../constants';
import {
  CollectionPageWidgetPresets,
  CollectionPageWidgetTabsArray,
} from './config/constants';
import { openDashboardCollections } from '../../utils/dashboardUtils';
import {
  setWidgetDesignPanel,
  setWidgetPresetPanel,
} from '../../utils/manifestUtils';

const setDesktopGFPP = (
  controllerBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  httpClient: IHttpClient,
  t,
  removeCollectionsPage: boolean,
  enableSubmenuSettingsPanel?: boolean,
  isEditorX?: boolean,
) => {
  const gfpp = controllerBuilder
    .gfpp()
    .set('mainAction1', {
      label: t('Collection_Page_Widget_Gfpp_Main_Action_1'),
      onClick: openDashboardCollections(
        editorSDK,
        removeCollectionsPage,
        httpClient,
      ),
    })
    .set('layout', { behavior: 'HIDE' })
    .set('animation', { behavior: 'HIDE' })
    .set('connect', { behavior: 'HIDE' })
    .set('add', { actionId: 'openElementsPanel' })
    .set('help', { id: defaultHelpId });
  if (isEditorX) {
    gfpp.set('mainAction2', { behavior: 'HIDE' });
  } else {
    gfpp.set('mainAction2', {
      label: t('Project_Page_Widget_Gfpp_Main_Action_2'),
      actionType: 'design',
      actionId: '',
    });
  }
  enableSubmenuSettingsPanel &&
    gfpp.set('settings', {
      actionId: 'openCollectionPageWidgetSettingsPanel',
    });
};

const setMobileGFPP = (
  controllerBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  httpClient: IHttpClient,
  t,
  removeCollectionsPage: boolean,
) => {
  controllerBuilder
    .gfpp('mobile')
    .set('mainAction1', {
      label: t('Collection_Page_Widget_Gfpp_Main_Action_1'),
      onClick: openDashboardCollections(
        editorSDK,
        removeCollectionsPage,
        httpClient,
      ),
    })
    .set('mainAction2', {
      behavior: 'HIDE',
    })
    .set('layout', { behavior: 'HIDE' })
    .set('animation', { behavior: 'HIDE' })
    .set('design', { behavior: 'HIDE' })
    .set('help', { id: defaultHelpId });
};

export const getWidgetManifest: GetWidgetManifestFn = (
  controllerBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  flowAPI: FlowAPI,
) => {
  const { httpClient } = flowAPI;
  const { t } = flowAPI.translations;
  const { isEditorX } = flowAPI.environment;
  const removeCollectionsPage = flowAPI.experiments.enabled(
    'specs.portfolio.removeCollectionsPage',
  );
  controllerBuilder.set({
    displayName: t('Widget_Name_Collections_Page_Widget'),
    nickname: 'collectionPageWidget ',
  });
  controllerBuilder.behavior().set({
    removable: false,
    duplicatable: false,
    toggleShowOnAllPagesEnabled: false,
    pinnable: false,
  });
  const enableSubmenuSettingsPanel = flowAPI.experiments.enabled(
    'specs.portfolio.enableSubmenuSettingsPanel',
  );
  setDesktopGFPP(
    controllerBuilder,
    editorSDK,
    httpClient,
    t,
    removeCollectionsPage,
    enableSubmenuSettingsPanel,
    isEditorX,
  );
  setMobileGFPP(
    controllerBuilder,
    editorSDK,
    httpClient,
    t,
    removeCollectionsPage,
  );
  setWidgetDesignPanel({
    controllerBuilder,
    t,
    presetsTabArray: CollectionPageWidgetTabsArray,
    title: t('Collection_Page_Widget_Design_Panel_Title'),
    isEditorX,
    isStudio: false,
  });
  setWidgetPresetPanel({
    controllerBuilder,
    PresetsArray: CollectionPageWidgetPresets,
  });
};
